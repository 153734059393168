<script>
import EventBus from "@/components/EventBus";
//Importing Bar and mixins class from the vue-chartjs wrapper
import { Bar, mixins } from "vue-chartjs";
//Getting the reactiveProp mixin from the mixins module.
const { reactiveProp } = mixins;
export default {
  extends: Bar,
  mixins: [reactiveProp],
  data() {
    return {
      //Chart.js options that control the appearance of the chart

      options: {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
              gridLines: {
                display: true,
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                display: false,
              },
            },
          ],
        },
        legend: {
          display: true,
        },
        responsive: true,
        maintainAspectRatio: false,
        onClick: function (point, event) {
          console.log("event : ", event);
          console.log("point : ", point);
          let item = "";
          if (typeof event[0] !== "undefined") {
            item = event[0];
            console.log("point : ", item._index);
          }
          EventBus.$emit("on_reactive_01", {
            index: item._index,
          });
        },
        listeners: {
          enter: function (context) {
            console.log("enter", context);
          },
          leave: function (context) {
            console.log("leave", context);
          },
          click: function (context) {
            console.log("click", context);
          },
        },
      },
    };
  },
  watch: {
    chartOptions() {
      // 옵션은 반응형 지원하지 않기 때문에 변경되었을 때 재렌더
      this.renderChart(this.chartData, this.options);
    },
  },
  mounted() {
    // this.chartData is created in the mixin and contains all the data needed to build the chart.
    this.renderChart(this.chartData, this.options);
  },
};
</script>
