<template>
  <v-container>
    <v-row>
      <v-col>
        <h3>월별 등록 현황</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <button class="button is-primary" @click="chartData0()">
          새로고침
        </button>
        <reactive-chart :chart-data="datacollection"></reactive-chart>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <!-- <button class="button is-primary" @click="fillData1()">새로고침</button> -->
        <!-- <reactive-chart1 :chart-data="datacollection1"></reactive-chart1> -->
        <v-simple-table>
          <template #default>
            <thead>
              <tr>
                <th
                  v-for="item in desserts1"
                  :key="item.name"
                  class="text-left"
                >
                  {{ item.name }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td v-for="item in desserts1" :key="item.name">
                  {{ item.score }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import EventBus from "@/components/EventBus";
import ReactiveChart from "@/components/ReactiveChart";

import { mapActions, mapGetters } from "vuex"; // mapGetters를 추가한다
const userStore = "userStore";

export default {
  name: "ApplicantChart2",
  components: {
    ReactiveChart,
  },
  data() {
    return {
      datacollection: {}, // instantiating datacollection with null
      desserts: [],
      desserts1: [],
      sido: [],
      sidocode: [],
      dataB: [],
      labelsB: [],
      payload: null,
    };
  },
  computed: {
    ...mapGetters("userStore", {
      items3: "GE_SERVICE2",
    }),
  },
  created() {
    this.chartData1();
    //this.getRandomIntA();
    //this.fillData(); //anytime the vue instance is created, call the fillData() function.
    EventBus.$on("on_reactive_01", (payload) => {
      this.payload = payload;
      //  this.chartData2(); //anytime the vue instance is created, call the fillData() function.
      console.log("payload", payload);
    });
  },
  methods: {
    ...mapActions(userStore, ["GET_DATA2"]),
    async chartData0() {
      this.dataB = [];
      this.labelsB = [];
      await this.chartData1();
    },
    async chartData1() {
      var response = await this.getRandomIntA();
      if (response.data.repCode == "00") {
        this.fillData();
      }
    },

    fillData() {
      this.datacollection = {
        // Data for the y-axis of the chart
        labels: this.sido,
        datasets: [
          {
            label: "Data One",
            backgroundColor: "#f87979",
            pointBackgroundColor: "white",
            borderWidth: 1,
            pointBorderColor: "#249EBF",
            // Data for the x-axis of the chart
            data: this.desserts,
          },
        ],
      };
    },

    async getRandomIntA() {
      try {
        const getResult = await this.GET_DATA2({
          fun_code: "chartselectC",
          cas_id: this.mCPID,
        });
        if (getResult.data.repCode == "00") {
          console.log("getResult 결과", getResult.data);
          let data = getResult.data.repData;
          this.desserts = [];
          this.sidocode = [];
          this.sido = [];
          data.forEach((repData) => {
            this.desserts.push(Number(repData.CNT));
            this.sido.push(repData.REG_DATE);
            this.sidocode.push(repData.REG_DATE_DESC);
            this.desserts1.push({
              score: Number(repData.CNT),
              name: repData.REG_DATE,
            });
          });
          console.log("desserts1", this.desserts1);

          console.log("정상 결과", this.desserts);
        } else {
          console.log("비정상 결과", getResult);
          this.desserts = getResult.data.repData;
        }
        return getResult;
      } catch (err) {
        console.error(err);
      }
    },
  },
};
</script>

<style scoped>
ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
